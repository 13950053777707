import {
  AcForm,
  AcFormCard,
  AcFormInput,
  AcLunchDaysTable,
  AcProfileTabTags,
  AcTabs,
  AcFormContext
} from '@components'
import {
  DAYS, KEYS, LABELS, TAG_CATEGORIES, WEEK_MENUS, WEEKDAYS,
  CUSTOMER_TYPES,
} from '@constants'
import { useStore } from '@hooks/use-store'
import { ICustomerProfile, IWeekMenu } from '@typings'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'
import { useMemo, useContext, useEffect } from 'react'
import styles from './customer-profile-form.module.scss'

export type ICustomerProfileForm = {
  onError: () => undefined | void
}

export const CustomerProfileForm = observer(
  ({ onError }: ICustomerProfileForm) => {
    const { tags, customer } = useStore()
    const FormInput = AcFormInput<ICustomerProfile>
    const formContext = useContext(AcFormContext)
    const { watch, setValue } = useFormContext()

    const watchCustomerType = watch('type')
    const week_menu = watch('week_menu')

    const getTagTabs = useMemo(() => {
      return [
        {
          label: TAG_CATEGORIES[0].label,
          component: () => (
            <AcProfileTabTags
              data={tags.current_diet_tags}
              loading={tags.loading}
            />
          ),
          disabled: false,
          id: 'first'
        },
        {
          label: TAG_CATEGORIES[1].label,
          component: () => (
            <AcProfileTabTags
              data={tags.current_allergy_tags}
              loading={tags.loading}
            />
          ),
          disabled: false,
          id: 'second'
        },
        {
          label: TAG_CATEGORIES[2].label,
          component: () => (
            <AcProfileTabTags
              data={tags.current_facility_tags}
              loading={tags.loading}
            />
          ),
          disabled: false,
          id: 'third'
        }
      ]
    }, [
      tags.loading,
      tags.current_allergy_tags,
      tags.current_diet_tags,
      tags.current_facility_tags,
      customer.profile_tags
    ])

    const getWeekdays = useMemo(() => {
      const keys = Object.keys(WEEKDAYS)
      const daysArray: {
        id: number
        label: string
        key: string
        abbr: string
      }[] = []
      return keys.reduce(
        (bulk, curr, i) => [
          ...bulk,
          {
            id: i,
            key: curr,
            label: WEEKDAYS[curr],
            abbr: DAYS.NL_SHORT[curr] as string
          }
        ],
        daysArray
      )
    }, [])

    const getCustomerTypes = useMemo(() => {
      const keys = Object.entries(CUSTOMER_TYPES)
      return keys.map(([key, value]) => ({
        id: key,
        key: key.toLowerCase(),
        label: value,
        abbr: value,
      }))
    }, [])

    const isBasicCustomerType = useMemo(() => {
      // Show true if loading
      // Otherwise fields will have a loading state 
      // and then suddenly disappear if customertype == basic
      if (formContext?.loading) return true
      return watchCustomerType === 'basic'
    }, [watchCustomerType])

    const getWeekMenus = useMemo(() => {
      if (isBasicCustomerType) {
        return WEEK_MENUS.filter(({ profile_key }) => profile_key !== 'soup_active')
      }
      return WEEK_MENUS
    }, [isBasicCustomerType])

    useEffect(() => {
      if (isBasicCustomerType && week_menu?.find(({ profile_key }: IWeekMenu) => profile_key === 'soup_active')) {
        setValue('week_menu', week_menu.filter(({ profile_key }: IWeekMenu) => profile_key !== 'soup_active'))
      }
    }, [isBasicCustomerType])

    return (
      <AcForm
        onError={onError}
        layout="column">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <AcFormCard title={LABELS.CUSTOMER_INFO} fitContent>
            <FormInput
              id={'type'}
              name={'type'}
              type="select-chip"
              labelKey="abbr"
              outputFormat="value"
              valueKey="key"
              options={getCustomerTypes}
              label={LABELS.TYPE}
              validate="required"
              allowMultiple={false}
            />
            {!isBasicCustomerType && (
              <FormInput
                id="delivery_days"
                name={'delivery_days'}
                type="select-chip"
                labelKey="abbr"
                options={getWeekdays}
                label={LABELS.DELIVERY_DAYS}
                valueKey="key"
                validate="required"
                outputFormat="value"
              />)}
            <FormInput
              id="week_menu"
              name="week_menu"
              type="select-chip"
              labelKey="label"
              outputFormat="object"
              valueKey="value"
              options={getWeekMenus}
              label={LABELS.WEEK_MENU}
              value={customer.profile.week_menu}
            />
            {!isBasicCustomerType && (
              <FormInput
                id={'eating_behaviour'}
                name={'eating_behaviour'}
                type="textarea"
                label={LABELS.EATING_BEHAVIOUR}
                validate="required"
              />
            )}
          </AcFormCard>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', minWidth: '50%' }}>
            <AcFormCard
              title={LABELS.LUNCHDAYS}>
              <AcLunchDaysTable showDietaryPreferences={isBasicCustomerType} />
            </AcFormCard>
            <AcFormCard
              title={LABELS.DATES}
              className={styles['ac-form-card-modified']}>
              <FormInput
                id={KEYS.FIRST_CREATED_AT}
                name={KEYS.FIRST_CREATED_AT}
                label={LABELS.CREATED}
                readOnly
                transform={{
                  input: value =>
                    value
                      ? dayjs(value as string).format('DD-MM-YYYY HH:mm')
                      : ''
                }}
              />
              <FormInput
                id={KEYS.UPDATED_AT}
                name={KEYS.UPDATED_AT}
                label={LABELS.LAST_UPDATED}
                readOnly
                transform={{
                  input: value =>
                    value
                      ? dayjs(value as string).format('DD-MM-YYYY HH:mm')
                      : ''
                }}
              />
            </AcFormCard>
          </div>
        </div>
        {!isBasicCustomerType && (
          <AcFormCard title={LABELS.TAGS}>
            <AcTabs tabs={getTagTabs} />
          </AcFormCard>)}
      </AcForm>
    )
  }
)
