import { AuthApi } from './auth.api'
import { CustomerApi } from './customer.api'
import { DataGridApi } from './datagrid.api'
import { EmployeesApi } from './employees.api'
import { GlobalSearchApi } from './global-search.api'
import { InvoicesApi } from './invoices.api'
import { ProductsApi } from './products.api'
import { SuppliersApi } from './suppliers.api'
import { TagsApi } from './tags.api'
import { TemplateApi } from './template.api'
import { UsersApi } from './users.api'
export interface IApi {
  auth: AuthApi
  products: ProductsApi
  datagrid: DataGridApi
  customer: CustomerApi
  employees: EmployeesApi
  invoices: InvoicesApi
  suppliers: SuppliersApi
  template: TemplateApi
  tags: TagsApi
  global_search: GlobalSearchApi
  users: UsersApi
}

export default (): IApi => {
  const auth = new AuthApi()
  const products = new ProductsApi()
  const datagrid = new DataGridApi()
  const customer = new CustomerApi()
  const employees = new EmployeesApi()
  const invoices = new InvoicesApi()
  const suppliers = new SuppliersApi()
  const global_search = new GlobalSearchApi()
  const template = new TemplateApi()
  const tags = new TagsApi()
  const users = new UsersApi()
  return {
    auth,
    products,
    datagrid,
    customer,
    employees,
    invoices,
    suppliers,
    global_search,
    template,
    tags,
    users
  }
}
