import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import styles from './ac-template-profile-list.module.scss'

import {
  AcChip,
  AcTemplateProfileHeader,
  AcTemplateProfileListItem,
  AcTemplateProfileListLink,
} from '@components'
import {
  DAYS,
  ICONS,
  KEYS,
  LABELS,
  ROUTE_PATHS,
  TEMPLATE_TAG_PREFERENCE_COLORS,
  WEEK_MENUS,
} from '@constants'
import { ICustomerProfileDayRow } from '@typings'

const translateDays = (dayList: string[]) =>
  dayList?.length
    ? dayList?.map((day: string) => <span key={day}>{DAYS.NL_SHORT[day]}</span>)
    : '-'

const translateLunchDays = (dayList: ICustomerProfileDayRow[]) =>
  dayList?.length
    ? dayList?.map(({ day }: ICustomerProfileDayRow) => (
        <span key={day}>{DAYS.NL_SHORT[day]}</span>
      ))
    : '-'

export const AcTemplateProfileList = observer(() => {
  const { template } = useStore()

  const mapWeekMenus = useMemo(() => {
    const menus = []
    if (template.current_profile?.soup_active === true) {
      menus.push(WEEK_MENUS.find(({ value }) => value === KEYS.SOUP)?.label)
    }

    if (template.current_profile?.salad_active === true) {
      menus.push(WEEK_MENUS.find(({ value }) => value === KEYS.SALAD)?.label)
    }

    if (menus.length < 1) return '-'
    return menus.join(', ')
  }, [
    template.current_profile?.salad_active,
    template.current_profile?.soup_active,
  ])

  const lastOrderLink = useMemo(() => {
    const lastOrderId = template.current_info?.last_order?.id

    if (!lastOrderId) return

    return `${ROUTE_PATHS.WEEK_ORDER_DETAIL.replace(
      ':id',
      lastOrderId.toString()
    )}`
  }, [template.current_info?.last_order?.id])

  const customerLink = useMemo(() => {
    const customerId = template.current_info?.customer?.id

    if (!customerId) return

    return `${ROUTE_PATHS.CUSTOMER_DETAIL.replace(
      ':id',
      customerId.toString()
    )}`
  }, [template.current_info?.customer?.id])

  const formulaLink = useMemo(() => {
    const templateId = template.current_info?.id

    if (!templateId) return

    return `${ROUTE_PATHS.FORMULA_DETAIL.replace(':id', templateId.toString())}`
  }, [template.current_info?.customer?.id])

  const renderProfileLunchesPerDay = useMemo(
    () => (
      <AcTemplateProfileListItem
        label={LABELS.NUMBER_OF_LPD}
        icon={ICONS.USERS_ALT}>
        {template.current_profile?.lunchers || '-'}
      </AcTemplateProfileListItem>
    ),
    [template.current_profile?.lunchers]
  )

  // const renderProducts = useMemo(() => {},[])

  const renderProfileDeliveryDays = useMemo(
    () => (
      <AcTemplateProfileListItem
        label={LABELS.DELIVERY_DAYS}
        icon={ICONS.CALENDAR_INCOMING}>
        <div className={styles['ac-template-profile-list__day-row']}>
          {translateDays(template.current_profile?.delivery_days)}
        </div>
      </AcTemplateProfileListItem>
    ),
    [template.current_profile?.delivery_days]
  )

  const renderProfileLunchDays = useMemo(
    () => (
      <AcTemplateProfileListItem
        label={LABELS.LUNCH_DAYS}
        icon={ICONS.CULTERY}>
        <div className={styles['ac-template-profile-list__day-row']}>
          {translateLunchDays(template.current_profile?.days)}
        </div>
      </AcTemplateProfileListItem>
    ),
    [template.current_profile?.days]
  )

  const renderProfileTags = useMemo(
    () =>
      template.current_profile_tags_by_category.map(({ label, icon, tags }) => (
        <AcTemplateProfileListItem
          key={label}
          label={label}
          icon={icon}>
          {tags.map(({ id, name, preference }) => (
            <AcChip
              key={id}
              label={name}
              outlined
              pill
              size="xs"
              badge={TEMPLATE_TAG_PREFERENCE_COLORS[preference]}
            />
          ))}
          {!tags?.length && '-'}
        </AcTemplateProfileListItem>
      )),
    [template.current_profile_tags_by_category]
  )

  const renderProfileEatingBehaviour = useMemo(
    () => (
      <AcTemplateProfileListItem
        icon={ICONS.BOOK_ALT}
        label={LABELS.EATING_BEHAVIOUR}>
        {template.current_profile?.eating_behaviour || '-'}
      </AcTemplateProfileListItem>
    ),
    [template.current_profile?.eating_behaviour]
  )

  const renderProfileWeekMenus = useMemo(
    () => (
      <AcTemplateProfileListItem
        label={LABELS.WEEK_MENU}
        icon={ICONS.LABEL_HEART}>
        <div className={styles['ac-template-profile-list__day-row']}>
          {mapWeekMenus}
        </div>
      </AcTemplateProfileListItem>
    ),
    [mapWeekMenus]
  )

  return (
    <div className={styles['ac-template-profile-list']}>
      <AcTemplateProfileHeader
        profile={template.current_profile}
        customer={template.current_info?.customer}
      />
      {renderProfileLunchesPerDay}
      {renderProfileWeekMenus}
      {renderProfileDeliveryDays}
      {renderProfileLunchDays}
      {renderProfileTags}
      {renderProfileEatingBehaviour}
      <div className={styles['ac-template-profile-list__links']}>
        <AcTemplateProfileListLink
          href={formulaLink}
          label={LABELS.VIEW_FORMULA}
          disabled={!formulaLink}
        />
        <AcTemplateProfileListLink
          href={customerLink}
          label={LABELS.VIEW_CUSTOMER_DATA}
          disabled={!customerLink}
        />
        <AcTemplateProfileListLink
          href={lastOrderLink}
          label={LABELS.VIEW_PREVIOUS_DELIVERY}
          disabled={!lastOrderLink}
        />
      </div>
    </div>
  )
})
