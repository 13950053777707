import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AcAppHeader, AcButton, AcAlert, AcFormProvider, AcIcon } from '@components'
import { ROUTE_PATHS, LABELS, GLOBAL_ERRORS, ICONS, KEYS, PERMISSIONS } from '@constants'
import { UserProfileForm } from '@partials/forms/user-profile-form/user-profile-form'
import { FieldValues, useForm } from 'react-hook-form'
import { ISingleUser } from '@typings'
import { toast } from 'react-toastify'
import { usePermissions } from '@hooks/use-permissions'
import { useProfileRolesManagement } from '@hooks/use-profile-roles-management'

export const AcUserProfile = observer(() => {
  const navigate = useNavigate()
  const { users } = useStore()
  const { id } = useParams()
  const { getProfile, watch_current_profile } = users
  const updatePermission = usePermissions([PERMISSIONS.USER.UPDATE])
  const archivePermission = usePermissions([PERMISSIONS.USER.DESTROY])
  const { isAllowedProfileManagement } = useProfileRolesManagement(watch_current_profile)

  const [editMode, setEditMode] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)

  const handleSuccess = (updatedUser: ISingleUser) => {
    setEditMode(false)
    form.reset(updatedUser)
    toast(LABELS.SAVED_PROFILE, {
      toastId: KEYS.SAVED_PROFILE,
      icon: <AcIcon icon={ICONS.CHECK} />,
    })
  }

  const getDefaultValues = async () => {
    if (id) {
      return await getProfile(parseInt(id))
    } else {
      return {}
    }
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const onError = () => setHasErrors(true)

  const handleErrors = (errors: Record<string, string[]>) => {
    setHasErrors(true)
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as any, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )
  }

  const onSubmit = async (data: FieldValues) => {
    setHasErrors(false)

    users.updateProfile(data as ISingleUser)
      .then(updatedUser => handleSuccess(updatedUser))
      .catch((e: any) => handleErrors(e))
  }

  const onFormSubmit = () => {
    form.handleSubmit(onSubmit)()
  }

  const onArchiveUser = (id: string) => {
    if (!isAllowedProfileManagement) return null
    users.archiveProfile(parseInt(id))
      .then(() => {
        navigate(ROUTE_PATHS.USER_LIST, { replace: true })
        users.clearCurrentProfile()
      })
      .catch(err => {
        setHasErrors(true)
        if (err.roles) {
          err.roles_contact = err.roles
          err.roles_employee = err.roles
        }
        handleErrors(err)
      })
  }

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.USER_LIST}
        title={watch_current_profile?.first_name || ''}
        action={
          <>
            {id && archivePermission && isAllowedProfileManagement && (
              <AcButton
                icon={users.current_profile?.archived ? ICONS.CHECK : ICONS.X_CIRCLE}
                color="secondary"
                onClick={() => onArchiveUser(id)}
                label={
                  users?.current_profile?.archived
                    ? LABELS.ACTIVE_ACTION
                    : LABELS.ARCHIVE_ACTION
                }
              />
            )}
            {updatePermission && isAllowedProfileManagement && (
              <AcButton
                onClick={editMode ? onFormSubmit : () => setEditMode(true)}
                label={editMode ? LABELS.SAVE : LABELS.MODIFY}
              />
            )}
          </>
        }
      />
      <AcAlert
        title={GLOBAL_ERRORS.FORM_STATE_INVALID}
        visible={hasErrors}
      />
      <AcFormProvider
        form={form}
        key={id}
        onSubmit={onFormSubmit}
        readOnly={!editMode}
        loading={users.loading}>
        <UserProfileForm onError={onError} />
      </AcFormProvider>
    </>
  )
})
