import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
    IRole,
    ISingleUser,
    ISingleUserCreate
} from '@typings'
import { AxiosResponse } from 'axios'

export class UsersApi extends AxiosBaseApi {
    get_profile = (id: number) =>
        this.api
            .get<AxiosResponse<{ data: ISingleUser }>>(ENDPOINTS.USERS.SINGLE(id))
            .then(response => response.data)

    get_roles = () =>
        this.api
            .get<AxiosResponse<{ data: IRole[] }>>(ENDPOINTS.USERS.ROLES())
            .then(response => response.data)

    create_profile = async (payload: ISingleUserCreate) => {
        return this.api
            .post<AxiosResponse<{ data: ISingleUser }>>(ENDPOINTS.USERS.LIST(), payload)
            .then(response => response.data)
    }

    update_profile = async (id: ISingleUser['id'], payload: ISingleUser) => {
        return this.api
            .put<AxiosResponse<{ data: ISingleUser }>>(ENDPOINTS.USERS.SINGLE(id), payload)
            .then(response => response.data)
    }

    archive_profile = async (id: ISingleUser['id']) => {
        return this.api
            .delete(ENDPOINTS.USERS.SINGLE(id))
            .then((res) => res)
    }

}
