/* eslint-disable max-len */
import { ITemplateDaysName } from '@typings'
import { WEEKDAYS } from './days.constants'

export const LABELS = {
  ACCOUNT: 'Account',
  ATTENTION: 'Let op',
  ADJUSTMENTS_CUSTOMER_PROFILE: 'Aanpassingen klantprofiel',
  ADMINISTRATION_ONLY: 'Mag alleen door administratie ingevuld worden',
  ACTIVE: 'Actief',
  ADD_CONTACT: 'Contact toevoegen',
  ADD_PRODUCT: 'Product toevoegen',
  ALL: 'Alle',
  ACTIVE_ACTION: 'Activeren',
  ARCHIVE_ACTION: 'Archiveren',
  ARCHIVED: 'Gearchiveerd',
  ARTICLECODE: 'Artikelcode',
  AVAILABILITY: 'Beschikbaarheid',
  AVAILABILITY_MODIFIED: 'Aangepast',
  BANANA: 'Banaan',
  FRUIT_BOX: 'Fruitkist',
  BACK: 'Terug',
  BIBI_SHUFFLE: 'Bibi shuffle',
  BIBI_ATTENTION: 'Bibi: Let op!',
  BB_NOTIFICATIONS: 'Meldingen MijnBB',
  BTW_TARIF: 'BTW Tarief',
  BUY_PRICE: 'Inkoopprijs',
  BUDGET_ROUNDED_TO_TWO_DECIMALS: 'Het bedrag wordt afgerond op 2 decimalen',
  CANCEL: 'Annuleren',
  CATEGORY: 'Categorie',
  CREATED: 'Aangemaakt',
  CLAIM: 'Claimen',
  CLAIM_DELETED: 'Claim verwijderd',
  CLAIM_DELETE: 'Claim verwijderen',
  CLAIMED_BY: 'Geclaimed door',
  CLAIMED_BEFORE_BY: 'Eerder geclaimed door',
  CLAIM_TAKEOVER: 'Claim overnemen',
  CLAIM_TAKEOVER_CONTENT:
    'Als je de claim van het sjabloon overneemt kan de huidige gebruiker het sjabloon alleen nog bekijken.',
  CLOSE: 'Sluit',
  CLEAR_DAY: 'Dag leeg maken',
  COPY_TO: 'Kopiëren naar',
  CONFIRM: 'Bevestigen',
  CONTINUE: 'Doorgaan',
  COUPONS: 'Coupons',
  CUSTOMER_PROFILE: 'Klantprofiel',
  CUSTOMER_INFO: 'Klant informatie',
  CUSTOMERS: 'Klanten',
  DAY_BUDGET_CLIENT: 'Dagbudget klant',
  DASHBOARD: 'Dashboard',
  DATES: 'Datums',
  DELIVERIES: 'Leveringen',
  DELIVERY_DAYS: 'Leverdagen',
  DELIVERY_RECEIPT: 'Pakbon',
  LUNCH_DAYS: 'Lunch dagen',
  LUNCHDAYS: 'Lunchdagen',
  DELIVERY_AT_BUURTBOER: 'Levering bij Buurtboer:',
  DELIVERY_AT_CUSTOMER: 'Levering bij klant op:',
  DELIVERY_MOMENTS: 'Bezorgmomenten',
  DESCRIPTION: 'Omschrijving',
  EATING_BEHAVIOUR: 'Eetgedrag',
  EDIT: 'Wijzig',
  EXPAND: 'Uitklappen',
  COLLAPSE: 'Inklappen',
  DELIVERY_MOMENTS_ALT: 'Levermomenten',
  EMPTY: 'Geen selectie',
  // eslint-disable-next-line quotes
  EXTRAS: "Extra's",
  EMPLOYEES: 'Werknemers',
  FRUIT: 'Fruit',
  FINISH_TASK: 'Taak afronden',
  EXACT_ARTICLE: 'Exact artikel',
  CLEAR: 'Wissen',
  CONFIRM_CLEAR_DAY: 'Weet je zeker dat je deze dag wilt leegmaken?',
  CONFIRM_REMOVE_DAY: 'Weet je zeker dat je deze dag wilt verwijderen?',
  CONFIRM_EXIT_PAGE_TITLE: 'Pagina verlaten?',
  CONFIRM_EXIT_PAGE_TEXT: `Weet je zeker dat je deze pagina wilt verlaten? <br/>
    De wijzigingen zijn nog niet opgeslagen.`,
  CONFIRM_PRODUCTS_ARCHIVE_TITLE: 'Product(en) archiveren?',
  CONFIRM_PRODUCTS_ARCHIVE_TEXT: `Weet je zeker dat je deze producten wilt archiveren? 
  De producten kunnen niet meer ingepland worden.`,
  CONFIRM_PRODUCTS_ACTIVATE_TITLE: 'Product(en) activeren?',
  CONFIRM_PRODUCTS_ACTIVATE_TEXT: `Weet je zeker dat je deze producten wilt activeren? <br/>
  De producten zijn direct beschikbaar voor planning.`,
  CONFIRM_SUPPLIER_ARCHIVE_TITLE: 'Leverancier archiveren?',
  CONFIRM_SUPPLIER_ARCHIVE_TEXT: `Weet je zeker dat je deze leverancier wilt archiveren? <br/>
  De producten van deze leverancier kunnen niet meer ingepland worden.`,
  CONFIRM_SUPPLIER_ACTIVATE_TITLE: 'Leverancier activeren?',
  CONFIRM_SUPPLIER_ACTIVATE_TEXT: `Weet je zeker dat je deze leverancier wilt activeren? <br/>
    De producten van deze leverancier zijn direct beschikbaar voor planning.`,
  CREATE_NEW_USER: 'Nieuwe gebruiker aanmaken',
  ID: 'ID',
  IMAGE: 'Afbeelding',
  INFORMATION: 'Informatie',
  INVOICES: 'Facturen',
  LEAVE_REACTION: 'Reactie achterlaten',
  LUNCHERS: 'Lunchers',
  LUNCH_DAY: 'Lunchdag',
  LUNCH_BUDGET_PER_PERSON: 'Lunchbudget per persoon',
  LUNCH_BUDGET_PER_PERSON_UPDATED: 'Lunchbudget per persoon aangepast',
  LUNCHER_COUNT: 'Aantal lunchers',
  LOCATION: 'Locatie',
  LOCK: 'Op slot zetten',
  LOGOUT: 'Uitloggen',
  LAST_UPDATED: 'Laatst aangepast',
  LAST_UPDATED_BY: 'Laatst aangepast door',
  MAKE_MENU: 'Menu maken',
  CHANGE_MENU: 'Menu wijzigen',
  MODIFY: 'Wijzigen',
  MORE_ACTIONS: 'Meer acties',
  MOVE_TO_NEXT_DAY: 'Verplaats naar volgende dag',
  MOVE_TO_PREVIOUS_DAY: 'Verplaats naar vorige dag',
  MANUALS: 'Handleidingen',
  MARGE: 'Marge',
  MENU: 'Menu',
  MIX_VEGETARIAN: 'Mix/Vega',
  MIX_VEGETARIAN_VEGAN: 'Mix/Vega/Vegan',
  NAME: 'Naam',
  NAME_FIRST: 'Voornaam',
  NAME_PREFIX: 'Tussenvoegsel',
  NAME_LAST: 'Achternaam',
  NEW: 'Nieuw',
  NUMBER_OF_LPD: 'Aantal LPD',
  NO_TASKS_AVAILABLE: 'Er zijn geen taken doorgegeven',
  NO_RESULTS: 'Geen resultaten',
  NO_PRODUCTS_FOUND_FOR: 'Geen producten gevonden voor',
  NO_COMMENT_AVAILABLE: 'Er zijn geen bijzonderheden doorgegeven',
  NO_TAGS_WITH_THIS_CATEGORY: 'Er zijn geen tags met deze categorie',
  // eslint-disable-next-line quotes
  PRODUCT_WEBSHOP_EXTRAS: "MijnBuurtboer extra's",
  WEBSHOP_NAME: 'Naam in MijnBuurtboer',
  WEBSHOP_ACTIVE: 'Zichtbaar als extra in Mijn Buurtboer',
  OPEN: 'Open',
  ORDERS: 'Bestellingen',
  ORGANISATIONS: 'Organisaties',
  PRODUCT: 'Product',
  PRICE: 'Prijs',
  PROFILE_AVATAR: 'Profielfoto',
  PRODUCTGROUP: 'Productgroep',
  PRODUCTS: 'Producten',
  PRODUCT_INFORMATION: 'Product informatie',
  PREVIOUS: 'Vorige',
  PRIMARY_CONTACT: 'Hoofdcontact',
  REMOVE_DAY: 'Dag verwijderen',
  REPEATS_EVERY_WEEK: 'Herhaalt elke week',
  REPEATS_EVERY_OTHER_WEEK: 'Herhaalt om de week',
  RECALCULATE: 'Herberekenen',
  REMOVE: 'Verwijder',
  REPEATING: 'Herhalend',
  REPLACE_PRODUCT: 'Product vervangen',
  REPLY: 'Reageren',
  ROLES_EMPLOYEE: 'Rollen werknemer',
  ROLES_CLIENT: 'Rollen klant',
  SALAD_AND_SOUPS_MENU: 'Salade en soepen menu',
  SAVE: 'Opslaan',
  SEARCH_PRODUCT: 'Zoek product',
  SEARCH_PLACEHOLDER: 'Waar ben je naar op zoek?',
  SEARCH_TITLE: 'Begin met zoeken',
  SEARCH_SUBTITLE:
    'Je kunt zoeken op klanten, <br/> bestelsjablonen en leveringen',
  SELECTED: 'geselecteerd',
  SELECT_ALL_ROWS: 'Selecteer alle rijen',
  SELECT_THIS_ROW: 'Selecteer deze rij',
  SALE_PRICE: 'Verkoopprijs',
  SEARCH: 'Search',
  SEPA_TOOLS: 'SEPA Tools',
  SEND_MENU: 'Menu versturen',
  SUPPLIER: 'Leverancier',
  SUPPLIER_INFORMATION: 'Informatie leverancier',
  SYSTEM: 'Systeem',
  TO_CHECKLIST: 'Naar checklist',
  VIEW_TEMPLATE: 'Sjabloon bekijken',
  TAG_CREATE: 'Tag aanmaken',
  TAG_EDIT: 'Tag bewerken',
  TAG_EDITED: 'Tag bewerkt',
  TAG_CREATE_INTRO:
    ' Bepaal hieronder de naam en categorie van de tag. Houd de naam van de tag zo kort mogelijk.',
  TAG_NAME: 'Tag naam',
  TAGS: 'Tags',
  TASK_FINISHED: 'Taak afgerond',
  TEMPLATES: 'Bestelsjablonen',
  TEMPLATE_TAKEOVER_TEXT:
    'Iemand heeft de claim van het sjabloon overgenomen, waardoor je het sjabloon alleen nog maar kan bekijken.',
  TEMPLATE_CLAIM_TEXT:
    'Je kunt het sjabloon claimen om te beginnen met plannen of het alleen bekijken zonder te bewerken.',
  TEMPLATE_VALIDATE_ALMOST_THERE:
    'Je bent er bijna! Verwerk de volgende punten om een betere match te krijgen.',
  TEMPLATE_VALIDATE_GO_BACK: 'Nee, ga terug',
  TEMPLATE_VALIDATE_CONFIRM: 'Ja, rond af',
  TAKE_OVER: 'Overnemen',
  TODO_LIST: 'To-do lijst',
  TYPE: 'Type',
  UPDATE_LUNCH_BUDGET_PER_PERSON: 'Lunchbudget per persoon aanpassen',
  UNIT: 'Eenheid',
  UNLOCK: 'Van het slot halen',
  USERS: 'Gebruikers',
  USER_OVERVIEW: 'Overzicht gebruiker',
  SUPPLIERS: 'Leveranciers',
  CONTACTS: 'Contacten',
  WEBSHOP_ORDERS: 'Buurtwinkel Orders',
  WEIGHT: 'Gewicht',
  SPACE: 'Ruimte',
  RACK: 'Stelling',
  ROLES_PERMISSIONS: 'Rollen en permissies',
  SHELVE: 'Plank',
  SEND: 'Versturen',
  COLOR: 'Kleur',
  SAVED_PRODUCT: 'Product opgeslagen',
  SAVED_SUPPLIER: 'Leverancier opgeslagen',
  SAVED_PROFILE: 'Klantprofiel opgeslagen',
  SELECT_CATEGORY: 'Selecteer categorie',
  STREET: 'Straat',
  TOTAL: 'Totaal',
  WEEK: 'Week',
  NUMBER_AND_APPENDIX: 'Nummer + toevoeging',
  PLANNED_TODAY: 'Vandaag ingepland',
  PLACEHOLDER: 'Placeholder',
  POSTALCODE: 'Postcode',
  CITY: 'Stad',
  PHONE: 'Telefoonnummer',
  EMAIL: 'E-mail',
  WEEK_MENU: 'Weekmenu',
  WEEK_ORDERS: 'Leveringen',
  WEEKS_ACTIVE: 'Weken actief',
  VIEW_CUSTOMER_DATA: 'Klant gegevens bekijken',
  VIEW_FORMULA: 'Formules bekijken',
  VIEW_PREVIOUS_DELIVERY: 'Voorgaande levering bekijken',
} as const

export const ACTION_LABELS = {
  LOCK: {
    LOCKED: (entity: string) => `${entity} op slot gezet`,
    UNLOCKED: (entity: string) => `${entity} van slot gehaald`,
  },
  DELETE: {
    DELETED: (entity: string) => `${entity} verwijderd`,
  },
  DAYS: {
    MOVE_FROM_TO: (from: ITemplateDaysName, to: ITemplateDaysName) =>
      `${WEEKDAYS[from]} verplaatst naar ${WEEKDAYS[to]}`,
  },
  COPY_FROM_TO: (from: ITemplateDaysName, to: ITemplateDaysName) =>
    `${WEEKDAYS[from]} verplaatst naar ${WEEKDAYS[to]}`,
  CLEAR: (day: ITemplateDaysName) => `${WEEKDAYS[day]} geleegd`,
  REMOVE: (day: ITemplateDaysName) => `${WEEKDAYS[day]} verwijderd`,
}

export const DYNAMIC_LABELS = {
  WEEK_N: (n: number) => `${LABELS.WEEK} ${n}`,
  WEEK_N_FROM_TILL: (n: number, from: string, till: string) =>
    `${LABELS.WEEK} ${n} (${from} t/m ${till})`,
  TEMPLATE_TODO_MENU_EMPTY: (week: number) =>
    `Geef het menu door voor week ${week}. 
    Deze is daarna direct zichtbaar voor de klant in Mijn Buurtboer.`,
  TAG_CREATED_SUCCESSFULLY: (name: string) =>
    `Tag '${name}' succesvol aangemaakt`,
  TEMPLATE_TODO_MENU_FILLED: (date: string) => `Menu doorgegeven op ${date}.`,
  CREATED_AT: (time: string) => `Aangemaakt op ${time}`,
  TEMPLATE_TAKEOVER: (employeeName: string, templateName: string) =>
    `${employeeName} heeft ${templateName} van je overgenomen`,
  CLAIMED_TEMPLATE_N: (templateName: string, employee: string) =>
    `Je hebt de claim op ${templateName} overgenomen van ${employee}`,
  TEMPLATE_CLAIMED_BY: (employeeName: string) =>
    `Sjabloon geclaimed door ${employeeName}`,
  CLAIM_TEMPLATE: (templateName: string) => `Wil je ${templateName} claimen?`,
  BIBI_NEGATIVE_PRODUCTS: (nr: number) =>
    `${nr} Uitgesloten ${nr === 1 ? 'product' : 'producten'} in planning`,
  BIBI_LIMITED_PRODUCTS: (nr: number) =>
    `${nr} Minder ${nr === 1 ? 'lekker product' : 'lekkere producten'
    } in planning`,
  BIBI_UNAVAILABLE_PRODUCTS: (nr: number) =>
    `${nr} Niet ${nr === 1 ? 'beschikbaar product' : 'beschikbare producten'}`,
  TEMPLATE_FINISH_WEEK_N_CUSTOMER: (n: number, customer: string) =>
    `${customer} - Week ${n} afronden?`,
  TEMPLATE_FINISH_WEEK_N: (n: number) => `Levering week ${n} afronden`,
  TEMPLATE_TODO_COMMENT_DATETIME: (
    week: number,
    time: string,
    weekday: string,
    date: string,
    contact: string
  ) => `Doorgegeven voor week ${week} om ${time}
    op ${weekday} ${date} door ${contact}`,
  TEMPLATE_VALIDATE_PENALTY: (penalty: number) => `Effect: -${penalty}`,
}
